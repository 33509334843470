import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { routeMap } from '/routeMap'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useContrastingStyle } from '/machinery/StyleContext'

import styles from './CtaWithOptionalImage.css'
import styleContext from '/cssGlobal/style-context.css'

export function CtaWithOptionalImage({ cta, layoutClassName = undefined }) {
  const { title, content, image, links } = cta ?? {}
  const contrastingStyleContext = useContrastingStyle({ white: styleContext.contextSand  }) || styleContext.contextWhite

  return (
    <div className={cx(styles.component, layoutClassName)} data-style-context={contrastingStyleContext}>
      {image?.asset && <CTAImage layoutClassName={styles.ctaImageLayout} {...{ image }} />}
      <Content layoutClassName={styles.contentLayout} {...{ title, content, links }} />
    </div>
  )
}

function CTAImage({ image, layoutClassName = undefined }) {
  const sizes = '(min-width: 1024px) 50vw, 100vw'

  return (
    <div className={cx(styles.componentCTAImage, layoutClassName)}>
      <ImageCover
        aspectRatio={6 / 5}
        layoutClassName={styles.imageLayout}
        imgProps={{ className: styles.image }}
        {...{ image, sizes }}
      />
    </div>
  )
}

function Content({ title, content, links, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <div className={styles.contentWrapper}>
        {title && <HeadingMd h='2' {...{ title }} />}
        {content && <Text {...{ content }} />}
        {links && <Buttons {...{ links }} />}
      </div>
    </div>
  )
}

function Text({ content }) {
  return <p>{content}</p>
}

function Buttons({ links, layoutClassName = undefined }) {
  const { ctaOne, ctaTwo } = links ?? {}

  return (
    <div className={cx(styles.componentButtons, layoutClassName)}>
      {ctaOne?.ref && <CTAButton cta={ctaOne} layoutClassName={styles.ctaButtonLayout} />}
      {ctaTwo?.ref && <CTAButton cta={ctaTwo} layoutClassName={styles.ctaButtonLayout} />}
    </div>
  )
}

function CTAButton({ cta, layoutClassName = undefined }) {
  const { ref, label, params } = cta ?? {}

  return (
    <ButtonGhost
      href={determineDocumentPathSyncWithParams({ document: ref, routeMap, params })}
      dataX='click-cta-button'
      {...{ layoutClassName }}
    >
      {label}
    </ButtonGhost>
  )
}
